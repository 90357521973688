<template>
    <div class="wrap-dashboard">
        <div class="main-content">
            <div class="wrap-content">
                <div class="content">
                    <table id="tableExcel">
                        <thead>
                            <tr>
                                <th colspan="16">
                                    Data Transaksi Clearing and Settlement
                                    <span v-show="dataFilter.startDate != '' || dataFilter.endDate != ''">
                                        {{ dataFilter.startDate + " - " + dataFilter.endDate }}</span
                                    >
                                </th>
                            </tr>
                        </thead>
                        <thead>
                            <tr id="top">
                                <th scope="col" rowspan="2" style="width: 70px">No</th>
                                <th scope="col" rowspan="2" style="width: 120px">Trade Matched Id</th>
                                <th scope="col" rowspan="2">TimeStamp</th>
                                <th scope="col" rowspan="2">Rate</th>
                                <th scope="col" colspan="11">Buyer</th>
                                <th scope="col" colspan="11">Seller</th>
                            </tr>
                            <tr>
                                <th scope="col">Order Id</th>
                                <th scope="col">Remarks</th>
                                <th scope="col">Trade Id</th>
                                <th scope="col">VA Code</th>
                                <th scope="col">Weight (gr)</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Fee</th>
                                <th scope="col">Tax Fee</th>
                                <th scope="col">Tax trx</th>
                                <th scope="col">Total Amount</th>
                                <th scope="col">Revenue Broker</th>
                                <th scope="col">Order Id</th>
                                <th scope="col">Remarks</th>
                                <th scope="col">Trade Id</th>
                                <th scope="col">VA Code</th>
                                <th scope="col">Weight (gr)</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Fee</th>
                                <th scope="col">Tax Fee</th>
                                <th scope="col">Tax trx</th>
                                <th scope="col">Total Amount</th>
                                <th scope="col">Revenue Broker</th>
                            </tr>
                        </thead>
                        <tbody v-if="loadingData">
                            <tr>
                                <td class="text-center" colspan="11">
                                    <b-spinner label="Loading..."></b-spinner>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-for="(data, i) in exceldatas" :key="i">
                                <td data-label="No" style="border-right: 1px solid #ddd">
                                    {{ (currentPage - 1) * perPage + (i + 1) }}
                                </td>
                                <td data-label="Trade Match Id" style="border-right: 1px solid #ddd">
                                    {{ data._id.order_match_id }}
                                </td>
                                <td data-label="Time Stamp" style="border-right: 1px solid #ddd">
                                    {{ data.timestamp | formatDateTimeReal }}
                                </td>
                                <td data-label="Rate" style="border-right: 1px solid #ddd">
                                    <div v-if="data.type_client == 'PC-LP' || data.type_client == 'PC-CP'">
                                        Buy
                                        {{ data.direction[0] ? " Rp" + data.direction[0].payment_info.rate.toLocaleString() : "" }}
                                    </div>
                                    <div v-else-if="data.type_client == 'LP-PC' || data.type_client == 'CP-PC'">
                                        Sell
                                        {{ data.direction[1] ? " Rp" + data.direction[1].payment_info.rate.toLocaleString() : "" }}
                                    </div>
                                    <div v-else>-</div>
                                </td>
                                <td data-label="Order Id" class="description">
                                    {{ data.direction[0] ? data.direction[0].order_id : " - " }}
                                </td>
                                <td data-label="Remarks" class="description">
                                    {{ data.direction[0] ? data.direction[0].remarks : " - " }}
                                </td>
                                <td data-label="Trade Id">
                                    <div v-show="data.direction[0].tradebuy">
                                        <span> BAGI - </span>
                                        <span>
                                            {{ " " + data.direction[0].tradebuy[0] + " - " }}
                                        </span>
                                        <span>{{ data.direction[0].tradebuy[2] }}</span>
                                    </div>
                                </td>
                                <td data-label="VA Code" class="num">
                                    {{ data.direction[0] ? "`" + data.direction[0].va_code : "" }}
                                </td>
                                <td data-label="Weight" class="num">
                                    {{ data.direction[0].gold_info ? data.direction[0].gold_info.total_gram : "" }}
                                </td>
                                <td data-label="Amount idr">
                                    {{ data.direction[0] ? data.direction[0].payment_info.payment_idr : "-" }}
                                </td>
                                <td data-label="Fee IDR" class="num">
                                    <div v-if="data.lpbuy == 'PC'">
                                        {{ data.direction[0].payment_info.fee_idr ? data.direction[0].payment_info.fee_idr.toFixed(2) : "-" }}
                                    </div>
                                    <div v-else>-</div>
                                </td>
                                <td data-label="tax fee" class="num">
                                    <div v-if="data.lpbuy == 'PC'">
                                        {{ data.direction[0].payment_info.tax_fee_idr ? data.direction[0].payment_info.tax_fee_idr.toFixed(2) : "-" }}
                                    </div>
                                    <div v-else>-</div>
                                </td>
                                <td data-label="tax trx" class="num">
                                    <div v-if="data.lpbuy == 'PC'">
                                        {{ data.direction[0].payment_info.tax }}
                                    </div>
                                    <div v-else>-</div>
                                </td>
                                <td data-label="Total Amount idr">
                                    {{ data.direction[0] ? data.direction[0].payment_info.payment_idr : "-" }}
                                </td>
                                <td data-label="Revenue Broker">
                                    {{ data.direction[0].payment_info.revenue_broker ? data.direction[0].payment_info.revenue_broker.toLocaleString() : "-" }}
                                </td>
                                <td data-label="Order Id">
                                    {{ data.direction[1] ? data.direction[1].order_id : "-" }}
                                </td>
                                <td data-label="Order Id">
                                    {{ data.direction[1] ? data.direction[1].remarks : "-" }}
                                </td>
                                <td data-label="Remarks">
                                    <div v-show="data.direction[1] ? data.direction[1].tradeSell : '-'">
                                        <span>
                                            BAGI -
                                            <!-- <img src="../assets/cimb.png" alt="" class="icon-trade" v-show="data.direction[1] ? data.direction[1].tradeSell[1] == '022' : ''" />
                      <img src="../assets/bagi.png" alt="" class="icon-trade" v-show="data.direction[1] ? data.direction[1].tradeSell[1] == '037' : ''" /> -->
                                        </span>
                                        <span>
                                            {{ data.direction[1] ? " " + data.direction[1].tradeSell[0] + " - " : "" }}
                                        </span>
                                        <span>{{ data.direction[1] ? data.direction[1].tradeSell[2] : " - " }}</span>
                                    </div>
                                </td>
                                <td data-label="VA Code">
                                    {{ data.direction[1] ? "`" + data.direction[1].va_code : "" }}
                                </td>
                                <td data-label="weight">
                                    {{ data.direction[1].gold_info ? data.direction[1].gold_info.total_gram : "-" }}
                                </td>
                                <td data-label="Amount IDR">
                                    {{ data.direction[1] ? data.direction[1].payment_info.amount_idr : "-" }}
                                </td>
                                <td data-label="fee IDR">
                                    <div v-if="data.lpsell == 'PC'">
                                        {{ data.direction[1].payment_info.fee_idr ? data.direction[1].payment_info.fee_idr.toFixed(2) : "-" }}
                                    </div>
                                    <div v-else>-</div>
                                </td>
                                <td data-label="tax fee">
                                    <div v-if="data.lpsell == 'PC'">
                                        {{ data.direction[1].payment_info.tax_fee_idr ? data.direction[1].payment_info.tax_fee_idr.toFixed(2) : "-" }}
                                    </div>
                                    <div v-else>-</div>
                                </td>
                                <td data-label="tax trx">
                                    <div v-if="data.lpsell == 'PC'">
                                        {{ data.direction[1].payment_info.tax ? data.direction[1].payment_info.tax.toFixed(2) : "-" }}
                                    </div>
                                    <div v-else>-</div>
                                </td>
                                <td data-label="Total Amount IDR">
                                    {{ data.direction[1] ? data.direction[1].payment_info.payment_idr : "-" }}
                                </td>
                                <td data-label="Revenue Broker">
                                    {{ data.direction[1].payment_info.revenue_broker ? data.direction[1].payment_info.revenue_broker.toLocaleString() : "-" }}
                                </td>
                            </tr>
                            <!-- <tr>
                                <td colspan="8">Total</td>
                                <td></td>
                            </tr> -->
                            <!-- <tr v-show="dataExcel.length == 0">
                                <td colspan="16">No Data Available</td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
// import downloadexcel from "vue-json-excel";
import * as XLSX from "xlsx";
export default {
    components: {
        // downloadexcel,
    },
    props: {
        dataFilter: Object,
    },
    data() {
        return {
            dataTransaction: [],
            pageOfItems: [],
            currentPage: 1,
            perPage: 10,
            lengthData: 1,
            trade_id: [],
            offset: 0,
            data: [],
            time_stamp: "",
            loadingData: false,
            today: "",
            role: "",
            form: {
                startDate: "",
                endDate: "",
                clientType: "",
                BankCode: "",
            },
            showToggle: false,
            codePerantara: "",
            bankCode: "",
            dataExcel: [],
        };
    },
    mounted() {
        this.today = new Date().toISOString().split("T")[0];
        this.role = sessionStorage.getItem("role");
    },
    created() {
        // this.$root.$on("inputExcel", (data) => {
        //     this.loadingData = true;
        //     let url;
        //     let role = sessionStorage.getItem("role");
        //     if (role == 2) {
        //         url =
        //             "/v2/clearing/admin/abx/get/customer/transaction?type=excel&startDate=" +
        //             data.startDate +
        //             "&endDate=" +
        //             data.endDate +
        //             "&offset=" +
        //             data.offset +
        //             "&code=" +
        //             data.client_type +
        //             "&bank_code=" +
        //             data.bank_code;
        //     } else if (role == 6) {
        //         url =
        //             "/v2/clearing/admin/pos/get/customer/transaction?type=excel&startDate=" +
        //             data.startDate +
        //             "&endDate=" +
        //             data.endDate +
        //             "&offset=" +
        //             data.offset +
        //             "&code=" +
        //             data.client_type +
        //             "&bank_code=" +
        //             data.bank_code;
        //     } else if (role == 4) {
        //         url =
        //             "/v2/clearing/admin/bank/get/customer/transaction?type=excel&startDate=" +
        //             data.startDate +
        //             "&endDate=" +
        //             data.endDate +
        //             "&offset=" +
        //             data.offset +
        //             "&code=" +
        //             data.client_type +
        //             "&bank_code=" +
        //             data.bank_code;
        //     } else {
        //         url =
        //             "/v2/clearing/admin/get/customer/transaction?type=excel&code=" +
        //             data.client_type +
        //             "&bank_code=" +
        //             data.bank_code +
        //             "&startDate=" +
        //             data.startDate +
        //             "&endDate=" +
        //             data.endDate +
        //             "&offset=" +
        //             data.offset;
        //     }
        //     axios.get(url).then((res) => {
        //         this.loadingData = false;
        //         this.dataExcel = res.data.data.body;
        //         this.lengthData = res.data.data.count;
        //         for (let i = 0; i < this.dataExcel.length; i++) {
        //             let trade;
        //             let tradeSell;
        //             let buy = {};
        //             let sell = {};
        //             if (this.dataExcel[i].direction.length < 2) {
        //                 if (this.dataExcel[i].direction[0].direction == "sell") {
        //                     tradeSell = this.dataExcel[i].direction[0].trade_id;
        //                     this.dataExcel[i].direction[1] = this.dataExcel[i].direction[0];
        //                     this.dataExcel[i].direction[1].tradeSell = tradeSell.split(";");
        //                     this.dataExcel[i].direction[0] = {
        //                         date_pay: "2021-12-27T15:15:09.599Z",
        //                         direction: "buy",
        //                         order_id: "-",
        //                         order_match_id: "-",
        //                         total_amount_idr: 0,
        //                         trade_id: "0;037;0",
        //                         tradebuy: ["0", "037", "0"],
        //                         va_code: "-",
        //                         _id: "61c9760d46d2db0025758775",
        //                     };
        //                 } else {
        //                     trade = this.dataExcel[i].direction[0].trade_id;
        //                     this.dataExcel[i].direction[0].tradebuy = trade.split(";");
        //                     this.dataExcel[i].direction[1] = "";
        //                 }
        //             } else {
        //                 if (this.dataExcel[i].direction[0].direction == "sell") {
        //                     buy = this.dataExcel[i].direction[1];
        //                     sell = this.dataExcel[i].direction[0];
        //                 } else {
        //                     buy = this.dataExcel[i].direction[0];
        //                     sell = this.dataExcel[i].direction[1];
        //                 }
        //                 this.dataExcel[i].direction[1] = sell;
        //                 tradeSell = this.dataExcel[i].direction[1].trade_id;
        //                 this.dataExcel[i].direction[1].tradeSell = tradeSell.split(";");
        //                 this.dataExcel[i].direction[0] = buy;
        //                 trade = this.dataExcel[i].direction[0].trade_id;
        //                 this.dataExcel[i].direction[0].tradebuy = trade.split(";");
        //                 this.dataExcel[i].type_client = trade.slice(0, 2) + "-" + tradeSell.slice(0, 2);
        //                 this.dataExcel[i].lpbuy = trade.slice(0, 2);
        //                 this.dataExcel[i].lpsell = tradeSell.slice(0, 2);
        //             }
        //         }
        //     });
        // });
        // this.$root.$on("toggleDownload", (data) => {
        //     this.tableToExcel(data.startDate, data.endDate);
        //     // this.tableToCsv(data.startDate, data.endDate);
        // });
    },

    methods: {
        tableToExcel(start, end) {
            var table = document.getElementById("testTable");
            let name = "data_transaksi";
            var filename = "Data_transaksi" + start + " - " + end;
            var uri = "data:application/vnd.ms-excel;base64,",
                template =
                    '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
                base64 = function (s) {
                    return window.btoa(unescape(encodeURIComponent(s)));
                },
                format = function (s, c) {
                    return s.replace(/{(\w+)}/g, function (m, p) {
                        return c[p];
                    });
                };

            if (!table.nodeType) table = document.getElementById(table);
            var ctx = { worksheet: name || "Worksheet", table: table.innerHTML };
            var a = document.createElement("a");
            a.href = uri + base64(format(template, ctx));
            a.download = filename;
            a.click();
        },
        tableToCsv(start, end) {
            var table = document.getElementById("testTable");
            let filename = "Data_transaksi" + start + " - " + end;
            let data_table = this.createDataCsv(table);
            let blob = new Blob([data_table], { type: "text/csv" });

            if (navigator.msSaveOrOpenBlob) {
                navigator.msSaveOrOpenBlob(blob, filename + ".csv");
            } else {
                this.toDownload(URL.createObjectURL(blob), filename, "csv");
            }
        },
        createDataCsv(table) {
            // We'll be co-opting `slice` to create arrays
            var slice = Array.prototype.slice;

            return slice
                .call(table.rows)
                .map(function (row) {
                    return slice
                        .call(row.cells)
                        .map(function (cell) {
                            return '"t"'.replace("t", cell.textContent);
                        })
                        .join(",");
                })
                .join("\r\n");
        },
        toDownload(content, filename, ext) {
            var anchor = document.createElement("a");
            // anchor.style = "display:none !important";
            anchor.id = "downloadanchor";
            document.body.appendChild(anchor);

            // If the [download] attribute is supported, try to use it

            if ("download" in anchor) {
                anchor.download = filename + "." + ext;
            }
            anchor.href = content;
            anchor.click();
            anchor.remove();
        },
        setDataDownload(data) {
            this.$emit("setLoadData", true);
            let url;
            let role = sessionStorage.getItem("role");
            if (role == 2) {
                url =
                    "/v2/clearing/admin/abx/get/customer/transaction?type=excel&startDate=" +
                    data.startDate +
                    "&endDate=" +
                    data.endDate +
                    "&offset=" +
                    data.offset +
                    "&code=" +
                    data.client_type +
                    "&bank_code=" +
                    data.bank_code;
            } else if (role == 6) {
                url =
                    "/v2/clearing/admin/pos/get/customer/transaction?type=excel&startDate=" +
                    data.startDate +
                    "&endDate=" +
                    data.endDate +
                    "&offset=" +
                    data.offset +
                    "&code=" +
                    data.client_type +
                    "&bank_code=" +
                    data.bank_code;
            } else if (role == 4) {
                url =
                    "/v2/clearing/admin/bank/get/customer/transaction?type=excel&startDate=" +
                    data.startDate +
                    "&endDate=" +
                    data.endDate +
                    "&offset=" +
                    data.offset +
                    "&code=" +
                    data.client_type +
                    "&bank_code=" +
                    data.bank_code;
            } else {
                url =
                    "/v2/clearing/admin/get/customer/transaction?type=excel&code=" +
                    data.client_type +
                    "&bank_code=" +
                    data.bank_code +
                    "&startDate=" +
                    data.startDate +
                    "&endDate=" +
                    data.endDate +
                    "&offset=" +
                    data.offset;
            }
            axios
                .get(url)
                .then((res) => {
                    this.dataExcel = res.data.data.body;
                    for (let i = 0; i < this.dataExcel.length; i++) {
                        let trade;
                        let tradeSell;
                        let buy = {};
                        let sell = {};

                        if (this.dataExcel[i].direction.length < 2) {
                            if (this.dataExcel[i].direction[0].direction == "sell") {
                                tradeSell = this.dataExcel[i].direction[0].trade_id;
                                this.dataExcel[i].direction[1] = this.dataExcel[i].direction[0];
                                this.dataExcel[i].direction[1].tradeSell = tradeSell.split(";");
                                this.dataExcel[i].direction[0] = {
                                    date_pay: "2021-12-27T15:15:09.599Z",
                                    direction: "buy",
                                    order_id: "-",
                                    order_match_id: "-",
                                    total_amount_idr: 0,
                                    trade_id: "0;037;0",
                                    tradebuy: ["0", "037", "0"],
                                    va_code: "-",
                                    _id: "61c9760d46d2db0025758775",
                                };
                            } else {
                                trade = this.dataExcel[i].direction[0].trade_id;
                                this.dataExcel[i].direction[0].tradebuy = trade.split(";");
                                this.dataExcel[i].direction[1] = "";
                            }
                        } else {
                            if (this.dataExcel[i].direction[0].direction == "sell") {
                                buy = this.dataExcel[i].direction[1];
                                sell = this.dataExcel[i].direction[0];
                            } else {
                                buy = this.dataExcel[i].direction[0];
                                sell = this.dataExcel[i].direction[1];
                            }

                            this.dataExcel[i].direction[1] = sell;
                            tradeSell = this.dataExcel[i].direction[1].trade_id;
                            this.dataExcel[i].direction[1].tradeSell = tradeSell.split(";");
                            this.dataExcel[i].direction[0] = buy;
                            trade = this.dataExcel[i].direction[0].trade_id;
                            this.dataExcel[i].direction[0].tradebuy = trade.split(";");
                            this.dataExcel[i].type_client = trade.slice(0, 2) + "-" + tradeSell.slice(0, 2);
                            this.dataExcel[i].lpbuy = trade.slice(0, 2);
                            this.dataExcel[i].lpsell = tradeSell.slice(0, 2);
                        }
                    }
                    setTimeout(() => {
                        this.$emit("setLoadData", false);
                        this.setToDownload(data.startDate, data.endDate);
                    }, 5000);
                })
                .catch(() => {
                    this.$emit("setLoadData", false);
                });
        },
        setToDownload(startDate, endDate) {
            const table = document.getElementById("tableExcel");
            const wb = XLSX.utils.table_to_book(table);
            var wscols = [
                { wch: 5 }, //A
                { wch: 15 }, //B
                { wch: 15 }, //C
                { wch: 15 }, //D
                { wch: 10 }, //E
                { wch: 20 }, //F
                { wch: 20 }, //G
                { wch: 20 }, //H
                { wch: 10 }, //I
                { wch: 15 }, //J
                { wch: 15 }, //K
                { wch: 15 }, //L
                { wch: 15 }, //M
                { wch: 15 }, //N
                { wch: 15 }, //O
                { wch: 10 }, //P
                { wch: 20 }, //Q
                { wch: 20 }, //R
                { wch: 20 }, //S
                { wch: 15 }, //T
                { wch: 15 }, //U
                { wch: 15 }, //V
                { wch: 15 }, //W
                { wch: 15 }, //X
                { wch: 15 }, //Y
                { wch: 15 }, //Z
                // { wch: 15 }, //AA
                // { wch: 10 }, //AB
                // { wch: 15 }, //AC
                // { wch: 10 }, //AD
            ];
            wb["Sheets"]["Sheet1"]["!cols"] = wscols;
            delete wb["D1"];
            XLSX.utils.format_cell(wb["D1"]);
            let filename = "Data_transaksi " + startDate + " - " + endDate + ".xlsx";
            XLSX.writeFile(wb, filename, { cellText: true });
        },
    },
    computed: {
        rows() {
            return (this.currentPage - 1) * this.perPage;
        },
        isLoadingData() {
            return this.loadingData;
        },
        exceldatas() {
            return this.dataExcel;
        },
    },
};
</script>

<style scoped>
.wrap-content {
    width: 98%;
    height: 100%;
    margin: 0 auto;
    padding: 20px 25px;
}

.content {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}

.description {
    text-align: left !important;
}

input:disabled {
    cursor: not-allowed;
}
</style>
